import store from "@/store/index";
import { FmtEnum } from "./enumUtil";
import { info } from "sass";

export const tableStyle = {
  methods: {
    // 表头样式
    handleHeaderCellStyle: function () {
      return "background-color:#EBF4FF;color:#333333;font-wight:400;";
    },
    // 鼠标移入
    handleCellEnter(row, column, cell) {
      if (column.property == "reimburseNo") {
        return (cell.style.fontWeight = 500);
      }
    },
    // 鼠标移出
    handleCellLeave(row, column, cell) {
      return (cell.style.fontWeight = 400);
    },
  },
};

//分页
export const pagingMethods = {
  data() {
    return {
      page: {
        total: 0,
        size: 15,
        current: 1,
      },
      pageSizes: [15, 30, 50, 100],
      pageNo: 1,
      pageSize: 10,
      tableTotal: 0,
    };
  },
  methods: {
    //切换分页条数
    handleSizeChange: function (value) {
      this.page.size = value;
      this.pageSize = value;
      if (this.tradeInvoiceParams && this.tradeInvoiceParams.pageSize) {
        this.tradeInvoiceParams.pageSize = value;
      }
      this.submitForm();
    },
    //翻页
    handleCurrentChange(value) {
      this.page.current = value;
      this.pageNo = value;
      if (this.tradeInvoiceParams && this.tradeInvoiceParams.pageNo) {
        this.tradeInvoiceParams.pageNo = value;
      }
      this.submitForm();
    },
  },
};
/*公共方法*/
export const commonsMethods = {
  methods: {
    /* 加载弹窗 */
    handleLoading(message) {
      const loading = this.$loading({
        lock: true,
        text: typeof message == "undefined" ? "拼命加载中..." : message,
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      return loading;
    },
  },
};
/* 日期操作 */
export const dateOption = {
  data() {
    return {
      dateOption: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  methods: {
    // 时间格式化
    handleDateFormat(row, column) {
      var date = row[column.property];
      if (date == undefined) {
        return "";
      }
      return this.$moment(date).format("YYYY-MM-DD");
    },
    dateFormat(dateStr, pattern = "YYYY-MM-DD HH:mm:ss") {
      return this.$moment(dateStr).format(pattern);
    },
  },
};

export const changeMethods = {
  methods: {
    // 枚举值转换
    handleValueToLabel(type, value) {
      const aos = this.$store.state.enums[type];
      return FmtEnum(aos, value);
    },
    fmtLongMessage(value) {
      return value
        ? value.length > 10
          ? value.substring(0, 10) + "..."
          : value
        : "--";
    },
    // 金额小写转大写
    handleSmallToCapitalize(price) {
      let fraction = ["角", "分"];
      let digit = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
      let unit = [
        ["圆", "万", "亿"],
        ["", "拾", "佰", "仟"],
      ];
      let head = price < 0 ? "负" : "";
      price = Math.abs(price);
      let upper = "";
      for (let i = 0; i < fraction.length; i++) {
        upper += (
          digit[Math.floor(price * 10 * Math.pow(10, i)) % 10] + fraction[i]
        ).replace(/零./, "");
      }
      upper = upper || "整";
      price = Math.floor(price);
      for (let i = 0; i < unit[0].length && price > 0; i++) {
        let p = "";
        for (let j = 0; j < unit[1].length && price > 0; j++) {
          p = digit[price % 10] + unit[1][j] + p;
          price = Math.floor(price / 10);
        }
        upper =
          p.replace(/(零.)*零$/, "").replace(/^$/, "零") + unit[0][i] + upper;
      }
      return (
        head +
        upper
          .replace(/(零.)*零圆/, "圆")
          .replace(/(零.)+/g, "零")
          .replace(/^整$/, "零圆整")
          .replace(".", "")
      );
    },

    formatNumber(
      ignoreZero,
      number,
      decimals = 2,
      decPoint2 = ".",
      thousandsSep = ","
    ) {
      number = (number + "").replace(/[^0-9+-Ee.]/g, "");
      let n = !isFinite(+number) ? 0 : +number;
      // console.log(n);
      if (ignoreZero && n == "0") {
        return "";
      } else {
        return this.formatMoney(number, decimals, decPoint2, thousandsSep);
      }
    },

    /**
     * @description 格式化金额
     * @param number：要格式化的数字
     * @param decimals：保留几位小数 默认0位
     * @param decPoint：小数点符号 默认.
     * @param thousandsSep：千分位符号 默认为,
     */
    formatMoney(number, decimals = 2, decPoint2 = ".", thousandsSep = ",") {
      number = (number + "").replace(/[^0-9+-Ee.]/g, "");
      let n = !isFinite(+number) ? 0 : +number;
      let prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
      let sep = typeof thousandsSep === "undefined" ? "," : thousandsSep;
      let dec = typeof decPoint === "undefined" ? "." : decPoint;
      let s = "";
      let toFixedFix = function (n, prec) {
        let k = Math.pow(10, prec);
        return "" + Math.round(n * k) / k;
      };
      s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
      let re = /(-?\d+)(\d{3})/;
      while (re.test(s[0])) {
        s[0] = s[0].replace(re, "$1" + sep + "$2");
      }
      if ((s[1] || "").length < prec) {
        s[1] = s[1] || "";
        s[1] += new Array(prec - s[1].length + 1).join("0");
      }
      return s.join(dec);
    },
  },
};
//全局混入到vue实例
export default {
  install: (Vue) => {
    // 按钮功能权限
    Vue.directive("role", {
      inserted: (el, bing) => {
        const funcList = store.state.permission.roles;
        let value = bing.value;
        // if (typeof value == 'string') {
        if (funcList.indexOf(value) < 0) {
          el.parentNode.removeChild(el);
        }
        // }
        // if (typeof value == 'object' && Array.isArray(value)) {
        //     let intersect = funcList.filter((item) => {
        //         return value.indexOf(item) !== -1
        //     })
        //
        //     if (intersect.length < 1) {
        //         el.parentNode.removeChild(el);
        //     }
        // }
      },
    });
    Vue.directive("hasPermi", {
      inserted: (el, binding) => {
        // const permissions = store.state.permission.roles;
        // const { value } = binding;
        // if (value && value instanceof Array && value.length > 0) {
        //   const hasPermissions = permissions.some((permission) => {
        //     return "'*:*:*'" === permission || value.includes(permission);
        //   });
        //   if (!hasPermissions) {
        //     el.parentNode && el.parentNode.removeChild(el);
        //   }
        // }
      },
    });
    Vue.directive("float", {
      bind(el, { value = 2 }) {
        el = ["TEXTAREA", "INPUT"].includes(el.nodeName) ? el : el.children[0];
        const RegStr =
          value === 0
            ? `^[\\+\\-]?\\d+\\d{0,0}`
            : `^[\\+\\-]?\\d+\\.?\\d{0,${value}}`;
        el.addEventListener("keyup", () => {
          el.value = el.value.match(new RegExp(RegStr, "g"));
          el.dispatchEvent(new Event("input"));
        });
      },
      unbind(el) {
        el.removeEventListener("keyup", () => {});
      },
    });
    Vue.mixin({
      methods: {
        // toast提示
        toast(message, type, resolve) {
          this.$message.closeAll();
          this.$message({
            message: message,
            type: type,
            duration: 5000,
          });
          if (resolve) resolve();
        },
        // 枚举值转换
        handleValueToLabel(type, value) {
          const aos = this.$store.state.enums[type];
          return FmtEnum(aos, value);
        },
        // 过滤去重
        uniqueBy(arr = [], fn) {
          const seen = {};
          return arr.filter((el) => {
            const e = fn(el);
            return !(e in seen) && (seen[e] = 1);
          });
        },
      },
    });
  },
};

export const copyRightMixin = {
  data() {
    return {
      copyRight: "",
      srcCopyRight: "",
    };
  },
  created() {
    this.getCopyRight();
  },
  methods: {
    disCopyRight() {
      if (this.copyRight) {
        this.srcCopyRight = this.copyRight;
        this.copyRight = "";
      } else {
        this.copyRight = this.srcCopyRight;
      }
    },
    getCopyRight() {
      const hostname = window.location.hostname;
      let actual;
      if (hostname == "localhost") actual = "DEVELOP";
      if (actual == undefined) {
        var ip = hostname.match(/\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}/g);
        if (ip) {
          actual = "OTHER";
        }
      }
      if (actual == undefined) {
        var domain = hostname.match(
          /([a-z0-9][a-z0-9\-]*?\.(?:com|cn|net|org|gov|info|la|cc|co|jp)(?:\.(?:cn|jp))?)$/
        );
        if (domain) {
          actual = domain[0];
        }
      }
      if (actual == undefined) actual = "OTHER";
      const map = {
        "dotax.cn":
          'Copyright © 2018-2025&nbsp;&nbsp;北京多啦财税科技有限公司&nbsp;&nbsp;版权所有&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/" target="_blank">京ICP备19008681号-1</a>',
        "iuky.cn":
          'Copyright @ 2021-2025&nbsp;&nbsp;北京多啦财税科技有限公司&nbsp;&nbsp;版权所有&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/" target="_blank">京ICP备19008681号-4</a>',
        "quandianpiao.com":
          'Copyright @ 2021-2025&nbsp;&nbsp;北京全电票科技有限公司&nbsp;&nbsp;版权所有&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2022029528号</a>',
        DEVELOP:
          'Copyright @ 2021-2025&nbsp;&nbsp;北京多啦财税科技有限公司&nbsp;&nbsp;版权所有&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/" target="_blank">京ICP备19008681号-4</a>',
        OTHER:
          'Copyright © 2018-2025&nbsp;&nbsp;北京多啦财税科技有限公司&nbsp;&nbsp;版权所有&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/" target="_blank">京ICP备19008681号-1</a>',
      };
      this.copyRight = map[actual];
    },
  },
};

// 顶部横向导航
export const topTabsMixin = {
  data() {
    return {
      tabsList: [],
      curTab: undefined,
    };
  },
  watch: {
    $route: {
      handler(to) {
        this.curTab = JSON.stringify({ path: to.path, query: to.query });
        this.tabsList = JSON.parse(localStorage.getItem("tmpTabsList")) || [];
      },
      immediate: true,
    },
  },
  methods: {
    tabRemove(name) {
      const { path } = JSON.parse(name);
      this.tabsList = this.tabsList.filter((item) => item.path != path);
      localStorage.setItem("tmpTabsList", JSON.stringify(this.tabsList));
      if (this.tabsList.length >= 1) {
        const tmpObj = {
          path: this.tabsList[this.tabsList.length - 1].path,
          query: this.tabsList[this.tabsList.length - 1].query,
        };
        this.curTab = JSON.stringify(tmpObj);
        this.$router.push(tmpObj);
      }
    },
    tabClick({ name }) {
      this.curTab = name;
      const { path, query } = JSON.parse(name);
      this.$router
        .push({ path, query })
        .then((infor) => {
          console.log("路由正常跳转：", infor);
        })
        .catch((error) => {
          console.log("路由跳转异常：", error);
        });
      // console.log(label, name);
    },
  },
};
