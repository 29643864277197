import sys from "./modules/sys";
import user from "./modules/user";
import reimbursement from "./modules/reimbursement";
import income from "./modules/income";
import permission from "./modules/permission";
import cooperate from "./modules/cooperate";
import invoiceIssue from "./modules/invoice-issue";

export default {
  sys,
  user,
  reimbursement,
  income,
  permission,
  cooperate,
  invoiceIssue,
};
